import React from "react";
import DashCard from "../DashCard";

const Apk = () => {
  return (
    <div className="dashbody">
      <div className="bocard">
        <DashCard />
      </div>
    </div>
  );
};

export default Apk;
